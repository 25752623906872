<template>
    <v-app>    
        <v-main>
            <v-container class="fill-height container-background" fluid></v-container>
            <v-container 
                class="fill-height justify-center" 
                fluid
            >
                <v-row 
                    align="center" 
                    justify="center" 
                    style="z-index:10"
                    class="my-auto"
                >
                    <v-col>
                        <v-card 
                            max-width="400"
                            class="elevation-12 pa-4 mx-auto">
                            <v-row
                                justify="center"
                            >
                                <v-img
                                    src="../../assets/logo.png"
                                    max-width="120"
                                    contain
                                    class="d-flex"
                                ></v-img>
                            </v-row>
                            <v-row no-gutters>                               

                                <v-col cols="12">
                                    <v-window v-model="step">
                                        <v-window-item 
                                            :value="1"
                                            transition="slide-x-transition"
                                        >
                                            <div class="pa-4">
                                                <v-alert                                                    
                                                    type="error"
                                                    outlined                                                    
                                                    v-if="loginErrors"
                                                    class="red--text"
                                                >
                                                    <div>{{ loginErrors }}</div>
                                                </v-alert>    
                                            </div>
                                            <div class="text-center font-weight-light primary--text">Welcome to</div>
                                            <div class="text-h4 text-sm-h5 font-weight-black text-center primary--text">{{ schoolName }}</div>  
                                            <v-card-text>                                                
                                                <div :class="title_css">
                                                    <slot name="header"></slot>    
                                                </div>                                            
                                            </v-card-text>
                                            <slot name="username"></slot>
                                        </v-window-item>

                                        <v-window-item
                                            :value="2"
                                            transition="slide-x-transition"
                                        >
                                            <div class="pa-4">
                                                <v-alert                                                    
                                                    type="error"
                                                    outlined                                                    
                                                    v-if="loginErrors"
                                                    class="red--text"
                                                >
                                                    {{ loginErrors }}
                                                </v-alert>    
                                            </div>
                                            <div class="text-center font-weight-light primary--text">Welcome to</div>
                                            <div class="text-h4 text-sm-h5 font-weight-black text-center primary--text">{{ schoolName }}</div>
                                            <v-card-text>                                                
                                                <div :class="title_css">
                                                    <slot name="header"></slot>    
                                                </div>                                            
                                            </v-card-text>
                                            <slot name="password"></slot>
                                        </v-window-item>

                                        <v-window-item :value="3">

                                            <div class="pa-4">
                                                <v-alert                                                    
                                                    type="error"
                                                    outlined                                                    
                                                    v-if="resetPasswordErrors"
                                                    class="red--text"
                                                >
                                                    
                                                </v-alert>    
                                            </div>

                                            <v-card-text>
                                                <div :class="resetPasswordClass">Reset Password</div>                                            
                                            </v-card-text>                                            

                                            <v-form 
                                                class="pa-5"
                                                @submit.prevent
                                            >
                                                <v-text-field                                                            
                                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show ? 'text' : 'password'"
                                                    label="New Password"                                                            
                                                    @click:append="show = !show"                                                    
                                                    name="new-password"
                                                    v-model="formData.newPassword"
                                                    color="primary"                                                            
                                                    autofocus
                                                >
                                                    <v-icon
                                                        slot="prepend"
                                                        color="primary"
                                                    >
                                                        mdi-key
                                                    </v-icon>
                                                </v-text-field>

                                                <v-text-field                                                            
                                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show ? 'text' : 'password'"
                                                    label="Confirm Password"                                                            
                                                    @click:append="show = !show"                                                    
                                                    name="confirm-password"
                                                    v-model="formData.confirmPassword" 
                                                    color="primary"
                                                    class="mt-4"                                                                                                                       
                                                >
                                                    <v-icon
                                                        slot="prepend"
                                                        color="primary"
                                                    >
                                                        mdi-key
                                                    </v-icon>
                                                </v-text-field>
                                            
                                        
                                                <div class="text-right mt-6">                                                
                                                    <v-btn 
                                                        color="primary" 
                                                        @click="changePassword" 
                                                        dark
                                                        block
                                                    >CHANGE PASSWORD</v-btn>
                                                </div>
                                            </v-form>

                                        </v-window-item>

                                    </v-window>
                                </v-col>

                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-overlay
                    :value="overlay"
                    opacity="0.8"
                    z-index="15"                    
                >
                    
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>                   
                </v-overlay>
            </v-container>               
        </v-main>
        
    </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'Login',
    data: () => ({
        //step: 1,
        resetPasswordErrors: false,
        resetPassword: false,
        show: false,
        formData: {
            newPassword: '',
            confirmPassword: '',
        },        
    }),
    computed: {
        ...mapGetters({
            getOverlay: 'auth/getOverlay',
            schoolName: 'app/getSchoolName',
            loginErrors: 'auth/getLoginErrors',
            getAuthenticated: 'auth/getAuthenticated',
            getUser: 'auth/getUser',
            step: 'auth/getStep',
            overlay: 'auth/getOverlay',            
        }),

        title_css(){
            return  'primary--text text-h5 text-left ';
        },
        resetPasswordClass(){
            return 'primary--text text-h5 text-left'
        }
    },
    methods: {
        ...mapActions({
            postNewPassword: 'auth/changePassword',
        }),
        ...mapMutations({
            setNewPassword: 'auth/setNewPassword',
            setAuthenticated: 'auth/setAuthenticated',
        }),
        async changePassword(){
            //console.log(this.formData);
            if(this.formData.newPassword && this.formData.confirmPassword){
                if(this.formData.newPassword == this.formData.confirmPassword){
                    console.log('changing password...');
                    this.setNewPassword(this.formData.confirmPassword);
                    try {
                        let response = await this.postNewPassword();
                        if(response.status == 200){
                            this.setAuthenticated(true);
                            this.$router.replace('/app');
                        }
                        console.log(response);
                    } catch (error) {
                        console.log(error);
                    }
                }
                else{
                    console.log('passwords dont match')
                }
            }
            else{
                console.log('passwords blank');
            }
        }    
    }
}
</script>

<style scoped>
    .container-background{
        background-image: url('../../assets/background.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        filter: brightness(40%);
        position: absolute;
    }
       
</style>