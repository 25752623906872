<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
        <v-card>
            <v-card-title class="text-h5 secondary primary--text">
                Add / Edit Form Class
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="formClasses"    
                    fixed-header           
                    height="40vh" 
                    :items-per-page="itemsPerPage" 
                    :loading="loading"         
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-spacer></v-spacer>

                            <v-dialog
                                v-model="dialogAddClass"
                                max-width="600px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="primary"
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                    >
                                        Add Class
                                    </v-btn>
                                </template>

                                <v-card>
                                    <v-card-title class="text-h5 secondary primary--text">
                                        {{ dialogText }}
                                    </v-card-title>
                                    <v-container class="my-4">
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field
                                                    v-model="formClass.form_class_id"
                                                    label="Class Name"
                                                    outlined
                                                    hide-details
                                                    dense
                                                ></v-text-field>
                                            </v-col>

                                            <v-col cols="6">
                                                <v-combobox
                                                    v-model="formClass.form_level"
                                                    label="Form Level"
                                                    outlined
                                                    hide-details
                                                    dense
                                                    :items="formLevels"
                                                ></v-combobox>
                                            </v-col>
                                        </v-row>
                                    </v-container>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            depressed
                                            small
                                            @click="dialogAddClass = false"
                                        >
                                            Cancel
                                        </v-btn>

                                        <v-btn
                                            color="primary"
                                            small
                                            @click="saveFormClass"
                                        >
                                            Save
                                        </v-btn>
                                    </v-card-actions>

                                    <v-overlay :value="overlay" absolute>
                                        <v-progress-circular
                                            indeterminate
                                            color="primary" 
                                        ></v-progress-circular>
                                    </v-overlay>
                                </v-card>
                            </v-dialog>

                            <v-dialog
                                v-model="dialogUpdateClass"
                                max-width="600px"
                            >
                                <v-card>
                                    <v-card-title class="text-h5 secondary primary--text">
                                        {{ dialogText }}
                                    </v-card-title>
                                    <v-container class="my-4">
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field
                                                    v-model="formClass.form_class_id_update"
                                                    label="Class Name"
                                                    outlined
                                                    hide-details
                                                    dense
                                                ></v-text-field>
                                            </v-col>

                                            <v-col cols="6">
                                                <v-combobox
                                                    v-model="formClass.form_level"
                                                    label="Form Level"
                                                    outlined
                                                    hide-details
                                                    dense
                                                    :items="formLevels"
                                                ></v-combobox>
                                            </v-col>
                                        </v-row>
                                    </v-container>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            depressed
                                            small
                                            @click="dialogAddClass = false"
                                        >
                                            Cancel
                                        </v-btn>

                                        <v-btn
                                            color="primary"
                                            small
                                            @click="update"
                                        >
                                            Update Class
                                        </v-btn>
                                    </v-card-actions>

                                    <v-overlay :value="overlay" absolute>
                                        <v-progress-circular
                                            indeterminate
                                            color="primary" 
                                        ></v-progress-circular>
                                    </v-overlay>
                                </v-card>
                            </v-dialog>

                            <v-dialog
                                v-model="dialogDelete"
                                max-width="600px"
                            >
                                <v-card>
                                    <v-card-title class="text-h5 secondary primary--text">
                                        Are you sure you want to delete this class?
                                    </v-card-title>
                                    <v-container class="my-4">
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field
                                                    v-model="formClass.form_class_id"
                                                    label="Class Name"
                                                    outlined
                                                    hide-details
                                                    dense
                                                    disabled
                                                ></v-text-field>
                                            </v-col>

                                            <v-col cols="6">
                                                <v-combobox
                                                    v-model="formClass.form_level"
                                                    label="Form Level"
                                                    outlined
                                                    hide-details
                                                    dense
                                                    :items="formLevels"
                                                    disabled
                                                ></v-combobox>
                                            </v-col>
                                        </v-row>
                                    </v-container>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            depressed
                                            small
                                            @click="dialogDelete = false"
                                        >
                                            Cancel
                                        </v-btn>

                                        <v-btn
                                            color="primary"
                                            small
                                            @click="confirmDeleteFormClass"
                                        >
                                            Delete Class
                                        </v-btn>
                                    </v-card-actions>

                                    <v-overlay :value="overlayDelete" absolute>
                                        <v-progress-circular
                                            indeterminate
                                            color="primary" 
                                        ></v-progress-circular>
                                    </v-overlay>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn
                            x-small
                            class="mr-4"
                            @click="editFormClass(item)"
                            color="primary"
                        >
                            Edit
                        </v-btn>

                        <v-btn
                            x-small
                            @click="deleteFormClassDialog(item)"
                            depressed
                        >
                            Delete
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-card-actions class="px-6">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    outlined
                    @click="close"
                >
                    Close
                </v-btn>
            </v-card-actions>

            <v-snackbar
                v-model="snackbar.display"
                :color="snackbar.color"
            >
                {{ snackbar.text }}
            </v-snackbar>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    props: {
        dialogOpen: Boolean,
    },

    created: function () {
        this.initialize();
    },
    data: function () {
        return {
            dialog: this.dialogOpen,
            headers: [
                { text: 'Class Name', value: 'id', sortable: false },
                { text: 'Form Level', value: 'form_level', sortable: false },
                { text: 'Action', value: 'action', sortable: false },
            ],
            formClasses: [],
            dialogAddClass: false,
            itemsPerPage: 6,
            formLevels: [1,2,3,4,5,6],
            formClass: {
                form_class_id: '',
                form_level: '', 
            },
            overlay: false,
            snackbar: {
                display: false,
                color: '',
                text: '',
            },
            dialogText: "Add Class",
            dialogDelete: false,  
            overlayDelete: false,   
            loading: false, 
            dialogUpdateClass: false, 
        }
    },

    methods: {
        close () {
            this.$emit('dialog-close');
        },

        async initialize () {
            console.log('initializing...');
            this.loading = true;
            try {
                const { data } = await this.getFormClasses();
                this.formClasses = data;
            } catch (error) {
                error.response ? console.log(error.response.data) : console.log(error);
            }
            this.loading = false;   
            
        },

        ...mapActions({
            getFormClasses: 'settings/getFormClasses',
            postFormClass: 'settings/postFormClass',
            updateFormClass: 'settings/updateFormClass',
            deleteFormClass: 'settings/deleteFormClass',
        }),

        ...mapMutations({
            setFormClass: 'settings/setFormClass',
        }),

        editFormClass (item) {
            console.log(item);
            this.formClass.form_class_id = item.id;
            this.formClass.form_level = item.form_level;
            this.formClass.form_class_id_update = item.id;
            this.dialogUpdateClass = true;
            
        },
        
        async update () {
            console.log('form class: ', this.formClass);
            this.setFormClass(this.formClass);
            this.overlay = true;
            try {
                const response = await this.updateFormClass();
                this.initialize();
                console.log(response);
                this.snackbar.color = 'success';
                this.snackbar.text = 'Form Class edited successfully';
            } catch (error) {
                error.response ? console.log(error.response.data) : console.log(error);
                this.snackbar.color = 'error';
                this.snackbar.text = 'An error occurred';
            }
            this.overlay = false;
            this.dialogUpdateClass = false;
            this.snackbar.display = true;
        },
        
        async saveFormClass () {
            console.log(this.formClass);
            this.setFormClass(this.formClass);
            this.overlay = true;
            try {
                const response = await this.postFormClass();   
                console.log(response); 
                this.initialize();
                this.snackbar.color = 'success';
                this.snackbar.text = 'Form Class added successfully';
            } catch (error) {
                error.response ? console.log(error.response.data) : console.log(error);
                this.snackbar.color = 'error';
                this.snackbar.text = 'An error occurred';
            }
            this.overlay = false;
            this.dialogAddClass = false;
            this.snackbar.display = true;
        },

        deleteFormClassDialog (item) {
            this.formClass.form_class_id = item.id;
            this.formClass.form_level = item.form_level;
            this.dialogDelete = true; 
        },

        async confirmDeleteFormClass () {
            this.setFormClass(this.formClass);
            this.overlayDelete = true;
            try {
                const response = await this.deleteFormClass();
                this.initialize();
                console.log(response);
                this.snackbar.color = 'success';
                this.snackbar.text = 'Form Class deleted successfully';
            } catch (error) {
                error.response ? console.log(error.response.data) : console.log(error);
                this.snackbar.color = 'error';
                this.snackbar.text = 'An error occurred. Class Not Deleted';
            }
            this.overlayDelete = false;
            this.dialogDelete = false;  
            this.snackbar.display = true;
        }

    }
}
</script>